<template>
  <div class="changeGoods">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/goods' }">商品管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/goods' }">商品列表</el-breadcrumb-item>
      <el-breadcrumb-item>编辑</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="changeGoods-content">
      <el-form
        :model="goodsForm"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="商品名称" prop="goodsName">
          <el-input
            v-model="goodsForm.goodsName"
            style="width: 240px"
            placeholder="请选择商品名称"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="商品状态" prop="goodsStatus">
          <el-select
            v-model="goodsForm.goodsStatus"
            placeholder="请选择商品状态"
            style="width: 150px"
          >
            <el-option label="上架" value="上架"></el-option>
            <el-option label="下架" value="下架"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item
          label="商品描述"
          prop="goodsText"
          :rules="[{ required: true, message: '门店介绍不能为空' }]"
          class="shopInfo-item"
          style="width: 590px"
        >
          <el-input
            placeholder="请输入门店介绍"
            type="textarea"
            v-model="goodsForm.goodsText"
            size="small"
            style="width: 420px"
            resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="编辑商品"
          prop="is_edit"
        >
          <el-select v-model="goodsForm.is_edit" filterable placeholder="请选择">
            <el-option
              v-for="item in typeOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="商品图片" class="shopInfo-item" prop="goodsImg">
          <div style="display: flex">
            <el-dialog :visible.sync="dialogVisible" :modal="false" width="40%">
              <img width="100%" :src="imgUrl + imageUrl" alt="" />
            </el-dialog>
            <el-upload
              :action="httpUrl + 'api/v1/images'"
              name="image"
              class="avatar-uploader"
              :show-file-list="false"
              :http-request="uploadURL"
            >
              <img v-if="imageUrl" :src="imgUrl + imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="imgTips">
              <div class="seeImg" @click="bigImg">浏览</div>
              <p style="margin-top: 10px">
                <span style="padding-right: 8px">*</span>图片大小：小于500KB
              </p>
              <p style="text-indent: 1em">图片尺寸：690px*316px</p>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="商品数量" prop="goodsNum">
          <el-input-number
            v-model="goodsForm.goodsNum"
            label="描述文字"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="取货时间" required>
          <el-time-picker
            :editable="false"
            :clearable="false"
            v-model="goodsForm.goodsTimeStart"
            :picker-options="{
              selectableRange: goodsTimeEnd
                ? ` 07:00:00- 23:59:00`
                : '07:00:00 - 23:59:00',
            }"
            format="HH:mm"
            placeholder="请选择开始时间"
            @change="startTimer"
          >
          </el-time-picker>
          <span style="width: 20px; text-align: center; display: inline-block"
            >-</span
          >
          <el-time-picker
            :editable="false"
            :clearable="false"
            v-model="goodsForm.goodsTimeEnd"
            :picker-options="{
              selectableRange: goodsTimeStart
                ? `${goodsTimeStart}:00 - 23:59:00`
                : '07:00:00 - 23:59:00',
            }"
            @change="endTimer"
            format="HH:mm"
            placeholder="请选择结束时间"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item style="display: flex; justify-content: center">
          <el-button
            type="primary"
            size="small"
            style="background: #cd0550; border: none"
            @click="changeGoods"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { imgUrl, httpUrl } from "@/utils/http.js"
import local from "@/utils/local.js"
import { changeGoodsInfo_api ,changeGoodsInfo_api1} from "@/api/goods.js"
//阿里云直传
import {client} from "@/utils/alioss.js"
export default {
  data() {
    return {
      imgUrl: imgUrl,
      httpUrl: httpUrl,
      goodsForm: {
        goodsName: "",
        goodsStatus: "", //状态
        goodsText: "", //描述
        is_edit:1,
        goodsImg: "",
        goodsNum: "",
        goodsTimeStart: "",
        goodsTimeEnd: "",
      },
      rules: {
        name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        goodsTimeStart: [{ required: true, message: "请选择开始时间", trigger: "change" }],
        goodsTimeEnd: [{ required: true, message: "请选择结束时间", trigger: "change" }],
        goodsNum: [{ required: true, message: "请选择商品数量", trigger: "blur" }],
        goodsImg: [{ required: true, message: "请选择商品图片", trigger: "blur" }],
        goodsStatus: [{ required: true, message: "请选择商品状态", trigger: "blur" }],
      },
      tk: "",
      imageUrl: "",
      goodsTimeStart: "",
      goodsTimeEnd: "",
      goodsInfo: "",
      dialogVisible:false,
      typeOptions: [{
        id: 0,
        name: '不可编辑'
      }, {
        id: 1,
        name: '可编辑'
      }],
    }
  },
  methods: {
     uploadURL(file){
      let fileName="uploads/chain/image/"+`${Date.parse(new Date())}`+'.png'
      console.log(fileName)
      client().multipartUpload(fileName,file.file).then(res=>{
        this.imageUrl=res.name
      })
    },
    //预览图片
    bigImg() {
      this.dialogVisible = true
    },
    //设置时间范围
    startTimer(e) {
      this.goodsTimeStart =
        (e.getHours() < 10 ? "0" + e.getHours() : e.getHours()) +
        ":" +
        (e.getMinutes() < 10 ? "0" + e.getMinutes() : e.getMinutes());
    },
    endTimer(e) {
      this.goodsTimeEnd =
        (e.getHours() < 10 ? "0" + e.getHours() : e.getHours()) +
        ":" +
        (e.getMinutes() < 10 ? "0" + e.getMinutes() : e.getMinutes());
    },
    //修改商品信息
    async changeGoods() {
      const aa=this.goodsInfo.goods_configuration_one.pickup_time_end.substr(0,2)*60+this.goodsInfo.goods_configuration_one.pickup_time_end.substr(3,2)*1
      const bb=this.goodsInfo.goods_configuration_one.pickup_time_start.substr(0,2)*60+this.goodsInfo.goods_configuration_one.pickup_time_start.substr(3,2)*1
      
      const cc=this.goodsTimeEnd.substr(0,2)*60+this.goodsTimeEnd.substr(3,2)*1
      const dd=this.goodsTimeStart.substr(0,2)*60+this.goodsTimeStart.substr(3,2)*1

      const ee=cc?cc:aa
      const ff=dd?dd:bb
      console.log(aa,bb,cc,dd)

       if(ff>ee){
        this.$message.error("结束时间必须大于开始时间")
        return
      }

      if(ee-ff<30){
        this.$message.error("结束时间距离开始时间必须大于30分钟")
        return
      }
      if(local.get("num")==1){
        console.log(1)
        let res = await changeGoodsInfo_api1({
        good_id: this.goodsInfo.id,
        good_name: this.goodsForm.goodsName,
        good_image: this.imageUrl,
        status: this.goodsForm.goodsStatus == "上架" ? 1 : 0,
        text: this.goodsForm.goodsText,
        is_edit: this.goodsForm.is_edit,
        good_quantity: this.goodsForm.goodsNum,
        pickup_time_start: this.goodsTimeStart
          ? this.goodsTimeStart
          : this.goodsInfo.goods_configuration_one.pickup_time_start,
        pickup_time_end: this.goodsTimeEnd
          ? this.goodsTimeEnd
          : this.goodsInfo.goods_configuration_one.pickup_time_end,
      });

      if (res.data.code == 0) {
        this.$message({
          message: "修改成功!",
          type: "success"
        })
        this.$router.push("/goods1")
      }
      }else{
        console.log(2)
         let res = await changeGoodsInfo_api({
        good_id: this.goodsInfo.id,
        good_name: this.goodsForm.goodsName,
        good_image: this.imageUrl,
        status: this.goodsForm.goodsStatus == "上架" ? 1 : 0,
        text: this.goodsForm.goodsText,
        is_edit:this.goodsForm.is_edit,
        good_quantity: this.goodsForm.goodsNum,
        pickup_time_start: this.goodsTimeStart
          ? this.goodsTimeStart
          : this.goodsInfo.goods_configuration_one.pickup_time_start,
        pickup_time_end: this.goodsTimeEnd
          ? this.goodsTimeEnd
          : this.goodsInfo.goods_configuration_one.pickup_time_end,
      })

      if (res.data.code == 0) {
        this.$message({
          message: "修改成功!",
          type: "success"
        })
        this.$router.push("/goods")
      }
      }
    }
  },
  created() {
    this.tk = local.get("tk")
    this.goodsInfo = local.get("goodsInfo")
    this.goodsForm.goodsName = this.goodsInfo.good_name
    this.goodsForm.goodsStatus = this.goodsInfo.status == 1 ? "上架" : "下架"
    this.goodsForm.goodsText = this.goodsInfo.content
    this.goodsForm.is_edit=this.goodsInfo.is_edit
    this.imageUrl = this.goodsInfo.good_image
    this.goodsForm.goodsTimeStart = new Date(
      "2020/2/20 " + this.goodsInfo.goods_configuration_one.pickup_time_start
    );
    this.goodsForm.goodsTimeEnd = new Date(
      "2020/2/20 " + this.goodsInfo.goods_configuration_one.pickup_time_end
    );
    this.goodsForm.goodsNum = this.goodsInfo.goods_configuration_one.good_quantity;
  }
}
</script>

<style lang="less" scoped>
.changeGoods {
  width: 100%;
  height: 100%;
  background: #eee;
  padding: 30px;
  box-sizing: border-box;
  .changeGoods-content {
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    margin-top: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
  }
}
.avatar-uploader {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  width: 346px;
  height: 160px;
}
.el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 346px;
  height: 160px;
  line-height: 160px;
  text-align: center;
}
.avatar {
  width: 346px;
  height: 160px;
  display: block;
}
.imgTips {
  color: #cd0550;
  font-size: 12px;
  font-weight: bold;
  margin-left: 20px;
  p {
    line-height: 26px;
  }
}
.seeImg {
  cursor: pointer;
  width: 70px;
  height: 36px;
  background: #cd0550;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
}
</style>